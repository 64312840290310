import React from 'react';
import Fade from 'react-reveal/Fade';

const Dots = props => (
  <div className="wr-dots">
    <Fade delay={800}>
      <span className="dot" />
    </Fade>
    <Fade delay={900}>
      <span className="dot" />
    </Fade>
    <Fade delay={1000}>
      <span className="dot" />
    </Fade>
    <Fade delay={1100}>
      <span className="dot" />
    </Fade>
    <Fade delay={1200}>
      <span className="dot" />
    </Fade>
    <Fade delay={1300}>
      <span className="dot" />
    </Fade>
    <Fade delay={1400}>
      <span className="dot" />
    </Fade>
    <Fade delay={1500}>
      <span className="dot" />
    </Fade>
    <Fade delay={1600}>
      <span className="dot" />
    </Fade>
    <Fade delay={1700}>
      <span className="dot" />
    </Fade>
    <Fade delay={1800}>
      <span className="dot" />
    </Fade>
    <Fade delay={1900}>
      <span className="dot" />
    </Fade>
  </div>
);


export default Dots;