import React from 'react';
import { Link, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import SEO from '../components/SEO';
import Dots from '../components/Dots';
import Img from 'gatsby-image';
import Layout from '../layouts/index';

const Services = (props) => {
  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />
      <div className="intro pt-6 pt-sm-8">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>What We Offer</h1>
            </div>
          </div>
          <hr className="section-divider mt-sm-7 mb-4 mb-sm-9" />
        </div>
        
      </div>
      
      <section>
        <div className="container pb-6">
          <div className="row">
          <div className="col-md-6 d-lg-flex flex-column justify-content-center">
            <div className="">
              <h2 className="title-2 text-primary mb-3">Frictionless Supply-Chain Financing</h2>
              <ul className="custom-list accent pr-lg-6">
                <li>If you are a <em>purchaser</em>, we supply a direct, online invoice and cash flow management system.</li>
                <li>If you are a <em>supplier</em>, you can easily monitor whether your invoices have been accepted and paid.</li>
                <li>If you are a <em>financier</em>, you now have access to a global network of business opportunities.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <Reveal effect="floatUp" duration={2000}>
              <div className="circle medium op-1" style={{top: 0, right: 0}}></div>
            </Reveal>
           <div className="bg-circle"></div>
            <div className="d-lg-flex justify-content-between wr-service-cards scatter-cards">
             <Fade delay={200}>
                <div className="card card-service">
                  <span>
                    <Img
                        fixed={props.data.serviceIcon1.childImageSharp.fixed}
                        critical={true}
                        fadeIn={true}
                        alt="Service Icon"
                      />
                  </span>
                  <p>Monitor your invoice acceptance and payment</p>
                </div>
              </Fade>
              <Fade delay={600}>
                <div className="card card-service">
                  <span>
                    <Img
                        fixed={props.data.serviceIcon2.childImageSharp.fixed}
                        critical={true}
                        fadeIn={true}
                        alt="Service Icon"
                      />
                  </span>
                  <p>Online invoice and cash flow management</p>
                </div>
              </Fade>
              <Fade delay={1000}>
                <div className="card card-service">
                  <span>
                    <Img
                        fixed={props.data.serviceIcon3.childImageSharp.fixed}
                        critical={true}
                        fadeIn={true}
                        alt="Service Icon"
                      />
                  </span>
                  <p>Global network of business opportunities</p>
                </div>
              </Fade>
            </div>
          </div>
          </div>
        </div>
      </section>

      <section>
        <div className="my-2 my-sm-6 container pb-6">
          <div className="row">
            
            <div className="col-sm-6 order-sm-last d-flex flex-column justify-content-center">
              <h2 className="title-2 text-primary mb-3">Effective Interactive Platform</h2>
              <ul className="custom-list accent">
                <li>Connects you to the global business population</li>
                <li>Enabled you to take decisions and make approvals on the move</li>
                <li>Access to information anywhere, anytime</li>
              </ul>
            </div>
            <div className="col-sm-6 order-sm-first">
              <div className="wr-service-img small">
                <Img
                    fluid={props.data.serviceInteractivePlatform.childImageSharp.fluid}
                    critical={true}
                    fadeIn={true}
                    className=""
                    alt="Service Image"
                  />
                </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pb-10">
          <h2 className="title-2 text-primary mb-3 text-center">Create Harmonious Relationships between the Supplier, Purchaser and Financier</h2>
          <div className="d-md-flex justify-content-between wr-service-cards">
            <Fade delay={200}>
              <div className="card card-service2">
                  <div>
                    <Img
                        fixed={props.data.purchaser.childImageSharp.fixed}
                        critical={true}
                        fadeIn={true}
                        alt="Service Icon"
                      />
                  </div>
                  <div>
                    <h4>For Purchasers</h4>
                    <p>Streamline your payment obligations by automatically managing your payables portfolio and making your cashflow predictable.</p>
                  </div>
              </div>
            </Fade>
            <Fade delay={600}>
              <div className="card card-service2">
                <div>
                  <Img
                      fixed={props.data.supplier.childImageSharp.fixed}
                      critical={true}
                      fadeIn={true}
                      alt="Service Icon"
                    />
                </div>
                <div>
                  <h4>For Suppliers</h4>
                  <p>Open up a range of supply chain funding with immensely reduced friction and much faster turnaround time.</p>
                </div>
              </div>
            </Fade>
            <Fade delay={1000}>
              <div className="card card-service2">
                <div>
                  <Img
                      fixed={props.data.financier.childImageSharp.fixed}
                      critical={true}
                      fadeIn={true}
                      alt="Service Icon"
                    />
                </div>
                <div>
                  <h4>For Financiers</h4>
                  <p>Greater investment opportunities in supply chain spectrum.</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-sm-6">
          <div className="row">
            <div className="col-sm-6 flex-column justify-content-center align-items-start">
              <h2 className="title-2 text-primary mb-3">Optimise Benefits with Machine Learning &amp; Blockchain Utilized Platform</h2>
              <p>
              Make informed decisions and optimise benefits with data ingestion and analytic techniques offered by our automated, transparent and auditable platform. Elevate your business process by utilizing cutting-edge technologies including machine learning and blockchain.
              </p>
              <div className="mb-4 mb-sm-0 align-self-center align-self-sm-start">
                <a href="/" className="btn btn-primary btn-csstudy">Read Our Case Study</a>
              </div>
            </div>
             <div className="col-sm-6">
                <div className="wr-service-img small">
                  <Img
                      fluid={props.data.blockchainUtilizedPlatform.childImageSharp.fluid}
                      critical={true}
                      fadeIn={true}
                      alt="Service Image"
                    />
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section mt-6 py-10">
        <Reveal effect="customFade">
          <div className="wr-svg wr-svg1">
            <svg xmlns="http://www.w3.org/2000/svg" width="1921.756" height="359.261" viewBox="0 0 1921.756 359.261">
              <path id="Path_805" data-name="Path 805" d="M-16293.918,14291.064s418.2,212.927,723.241,272.335,249.892-165.967,456.407-165.967,247.065,195.2,422.462,143.333,313.063-248.948,313.063-248.948v358.508H-16300.5Z" transform="translate(16300.5 -14291.064)" fill="rgba(0,70,204,0.15)" opacity="0.36"/>
            </svg>
          </div>
        </Reveal>
        <Reveal effect="customFade">
          <div className="wr-svg wr-svg2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1918.554" height="354.622" viewBox="0 0 1918.554 354.622">
              <path id="Path_804" data-name="Path 804" d="M-16300.5,14629.466s-32.924-248.146,247.013-333.017,328.306,105.854,577.111,132.693,541.275-167.512,739.3-132.693,353.932,132.693,353.932,132.693v199.116Z" transform="translate(16301.694 -14274.844)" fill="rgba(0,53,154,0.07)" opacity="0.96"/>
            </svg>
          </div>
        </Reveal>
        <div className="container text-center">
          <h2 className="title-2 text-primary mb-3">Explore Our Solution</h2>
          <p className="section-description text-dark mb-4 px-lg-5">Lilardia Capital delivers an effective interactive online platform to solve and secure your short term business financing problems. Freeing up your time to do what you do best…achieving results.</p>
        </div>
      </section>

      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-sm-6 flex-column justify-content-center align-items-start">
              <h2 className="title-2 text-primary">Lilardia Capital Marketplace</h2>
              <h3 className="title-4 mb-3">Lilardia capital payables automation and financing service platform</h3>
              <div className="row">
                <div className="col-lg-6">
                  <ul className="custom-list primary">
                    <li>Purchaser centric financing</li>
                    <li>Total treasury management system</li>
                    <li>Offers payables & receivables management</li>
                    <li>Pay as You Go subscription</li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="custom-list primary">
                    <li>KYC (Know Your Customer)</li>
                    <li>Common Infrastructure with partitioned communities</li>
                    <li>Robust credit engine</li>
                    <li>Trust & security</li>
                  </ul>
                </div>
              </div>
            </div>
             <div className="col-sm-6">
                <div className="wr-service-img service-1">
                  <Img
                    fluid={props.data.marketplaceImg.childImageSharp.fluid}
                    critical={true}
                    fadeIn={true}
                    alt="Service Image"
                  />
                  <Dots />
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-6">
        <div className="container">
          <div className="row">
            
            <div className="col-sm-7 order-sm-last d-flex flex-column justify-content-center align-items-start">
              <div className="pl-lg-5 ">
                <h2 className="title-2 text-primary">Lilardia Capital Platform</h2>
                <h3 className="title-4 mb-3">Unified platform for supply-chain management and financing</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="custom-list primary">
                      <li>Purchaser and supplier centric financing</li>
                      <li>Offers financing platform</li>
                      <li>Monthly payed Usage License</li>
                      <li>KYC (Know Your Customer)</li>
                      <li>Dedicated Infrastructure (Cloud)</li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul className="custom-list primary">
                      <li>Credit Modelling</li>
                      <li>Invoice Registration</li>
                      <li>Total treasure management system</li>
                      <li>Offers payables & receivables management</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 order-sm-first">
              <div className="wr-service-img service-2">
                <Img
                    fluid={props.data.platformImg.childImageSharp.fluid}
                    critical={true}
                    fadeIn={true}
                    alt="Service Image"
                  />
                  <Dots />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section banner-section2 bg-white">
       <Reveal effect="customFade">
          <div className="wr-svg wr-svg1">
            <svg xmlns="http://www.w3.org/2000/svg" width="1921.756" height="359.261" viewBox="0 0 1921.756 359.261">
              <path id="Path_805" data-name="Path 805" d="M-16293.918,14291.064s418.2,212.927,723.241,272.335,249.892-165.967,456.407-165.967,247.065,195.2,422.462,143.333,313.063-248.948,313.063-248.948v358.508H-16300.5Z" transform="translate(16300.5 -14291.064)" fill="rgba(0,70,204,0.15)" opacity="0.36"/>
            </svg>
          </div>
        </Reveal>
        <Reveal effect="customFade">
          <div className="wr-svg wr-svg2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1918.554" height="354.622" viewBox="0 0 1918.554 354.622">
              <path id="Path_804" data-name="Path 804" d="M-16300.5,14629.466s-32.924-248.146,247.013-333.017,328.306,105.854,577.111,132.693,541.275-167.512,739.3-132.693,353.932,132.693,353.932,132.693v199.116Z" transform="translate(16301.694 -14274.844)" fill="rgba(0,53,154,0.07)" opacity="0.96"/>
            </svg>
          </div>
        </Reveal>
        <div className="container py-10">
          <h2 className="title-2 text-primary mb-1 text-center">Lilardia Capital Platform Financing Solution</h2>
          <h3 className="title-4 mb-3 text-center">A suite of treasury management tools creating a frictionless, authenticated, transparent workflow, generating trust in the supply-chain.</h3>
          <div className="d-md-flex justify-content-between wr-service-cards">
            <Fade delay={200}>
              <div className="card card-service3">
                <div>
                  <h4>Discounting</h4>
                  <p>Initiated by the supplier. Facility is based on the credibility of the supplier</p>
                </div>
              </div>
            </Fade>
            <Fade delay={600}>
              <div className="card card-service3">
                <div>
                  <h4>Factoring</h4>
                  <p>Initiated by the supplier. Facility is based on the credibility of the supplier</p>
                </div>
              </div>
            </Fade>
            <Fade delay={1000}>
              <div className="card card-service3">
                <div>
                  <h4>Reverse Factoring</h4>
                  <p>Promoted by the purchaser and initiated by the supplier. Facility is based on the credibility of the purchaser.</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="banner-section primary py-7">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center">
            <h3 className="title-3 text-light">Whether you are a purchaser, supplier or financier, <br/> 
            Lilardia Capital’s vision is to connect YOU with your business world.</h3>
            <div className="mt-4 mt-sm-0 text-center text-sm-left">
              <Link to="/contact" className="btn btn-secondary">Contact Us</Link>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
};

export const query = graphql`
  query {
    serviceIcon1: file(relativePath: { eq: "service-icon1.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    serviceIcon2: file(relativePath: { eq: "service-icon2.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    serviceIcon3: file(relativePath: { eq: "service-icon3.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    purchaser: file(relativePath: { eq: "Purchaser.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    supplier: file(relativePath: { eq: "Supplier.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    financier: file(relativePath: { eq: "Financier.png" }) {
      childImageSharp {
        fixed(width: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    serviceInteractivePlatform: file(relativePath: { eq: "Effective-Interactive-Platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blockchainUtilizedPlatform: file(relativePath: { eq: "Blockchain-Utilized-Platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketplaceImg: file(relativePath: { eq: "offering-img3.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    platformImg: file(relativePath: { eq: "offering-img4.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Services;
